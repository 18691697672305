import React from 'react';

import MetaTags from '@/components/meta-tags';
import Shop from '@/features/shop';

const SCShop = () => {
  return <Shop />;
};

export default SCShop;

export function Head() {
  return (
    <>
      <title>
        Super Champs HQ - COMPLETE QUESTS, EARN QUEST POINTS, COMPETE FOR 40MM
        $CHAMP
      </title>

      {/** Work Sans Google font  */}
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      <link
        href="https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;600;700;800;900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap"
        rel="stylesheet"
      />
      <MetaTags title="SuperChamps - COMPLETE QUESTS, EARN QUEST POINTS, COMPETE FOR 40MM $CHAMP" />

      <script
        src="https://cdn.withpersona.com/dist/persona-v4.11.0.js"
        id="persona"
      ></script>
    </>
  );
}
