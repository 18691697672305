import React, { FC, useEffect } from 'react';

import { TTimer } from '@/common/types';
import utils from '@/common/utils';

export interface ITimerAdvanced {
  time: number;
  prefix?: string;
  maxDigitCount?: number;
  className?: string;
  onTimeUp?: () => void;
  ids: {
    days: string;
    hours: string;
    minutes: string;
    seconds: string;
  };
}
const TimerAdvanced: FC<ITimerAdvanced> = ({
  prefix,
  maxDigitCount: digitCount = 2,
  time,
  className,
  onTimeUp,
  ids,
}: ITimerAdvanced) => {
  let timerHolder: NodeJS.Timeout;

  const el = (id: string) => {
    return document.getElementById(id);
  };

  const tickTimer = () => {
    const timeNow = new Date().getTime() / 1000;
    const timerSeconds = time ? time - timeNow : null;
    if (timerSeconds && timerSeconds >= 0) {
      const timeObject = utils.secondsToTime(timerSeconds);
      updateDisplay(timeObject);
    }
    // Execute time-up function when the timer runs out
    if (onTimeUp && timerSeconds && Math.floor(timerSeconds) <= 0) {
      onTimeUp();
    }
    timerHolder = setTimeout(tickTimer, 1000);
  };

  const updateDisplay = (timeObject: TTimer) => {
    let localCount = 0;

    localCount = updateElement(ids.days, timeObject.days, 'D', localCount, '0');
    localCount = updateElement(
      ids.hours,
      timeObject.hours,
      'H',
      localCount,
      timeObject.days,
    );
    localCount = updateElement(
      ids.minutes,
      timeObject.minutes,
      'M',
      localCount,
      timeObject.hours,
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    localCount = updateElement(
      ids.seconds,
      timeObject.seconds,
      'S',
      localCount,
      timeObject.minutes,
    );

    updateSeparator(ids.days, ids.hours);
    updateSeparator(ids.hours, ids.minutes);
    updateSeparator(ids.minutes, ids.seconds);
  };

  const updateElement = (
    id: string,
    timeValue: string | number,
    suffix: string,
    localCount: number,
    prevTimeValue: string | number,
  ) => {
    const element = el(id);
    if (
      element &&
      (Number(timeValue) > 0 || Number(prevTimeValue) > 0) && // if days is present, we show hours, if hours is present, we show minutes, if minutes is present, we show seconds
      localCount < digitCount
    ) {
      element.style.display = 'flex';
      element.parentElement!.style.display = 'flex';
      element.innerText = `${timeValue}${suffix}`;
      localCount++;
    } else if (element) {
      element.parentElement!.style.display = 'none';
      element.style.display = 'none';
    }
    return localCount;
  };

  const updateSeparator = (prevId: string, nextId: string): void => {
    const prevElement = el(prevId);
    const nextElement = el(nextId);
    const separator = el(`${prevId}-separator`);
    if (separator) {
      separator.style.display =
        prevElement?.style.display === 'none' ||
        nextElement?.style.display === 'none'
          ? 'none'
          : '';
    }
  };

  useEffect(() => {
    // clearing timeout on unloading the component
    tickTimer();
    return () => {
      clearTimeout(timerHolder);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  return (
    <div className={`time ${className}`}>
      <div className="bit">
        <h2>{prefix}</h2>
      </div>
      <div className="bit">
        <h2 id={ids.days}>00D</h2>
      </div>
      <div id={ids.days + '-separator'} className="time-separator">
        :
      </div>
      <div className="bit">
        <h2 id={ids.hours}>00H</h2>
      </div>
      <div id={ids.hours + '-separator'} className="time-separator">
        :
      </div>
      <div className="bit">
        <h2 id={ids.minutes}>00M</h2>
      </div>
      <div id={ids.minutes + '-separator'} className="time-separator">
        :
      </div>
      <div className="bit">
        <h2 id={ids.seconds}>00S</h2>
      </div>
    </div>
  );
};

export default TimerAdvanced;
