import React, { useEffect, useCallback, useState } from 'react';

import {
  getShopItems,
  getUserMetaData,
  getUsersQuestsProgression,
} from '@/api/shop';
import { commonBiEvents } from '@/bi/events/common-bi-events';
import {
  getAppIdFromStoreId,
  getSinglePurchaseItemId,
} from '@/common/shopUtils';
import LStorage from '@/common/storage';
import utils, { getCurrentTabForBi } from '@/common/utils';
import SCLoader from '@/components/common/sc-loader';
import SuperChampsLayout from '@/components/layout/superchamps-layout';
import { useAuth } from '@/context/auth-context';

import { TUserProgression } from '../quests/types';

import ShopItem from './shop-item';
import { ShopItemDto, UserShopItemMetadata } from './types';

const Shop = () => {
  const USER_SHOP_METADATA = 'USER_SHOP_METADATA';

  const { user } = useAuth();
  const [shopItems, setShopItems] = useState<ShopItemDto[]>([]);
  const [shopItemsMetaData, setShopItemsMetaData] = useState<
    UserShopItemMetadata[]
  >([]);
  const [filter, setFilter] = useState('All Games');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [category, setCategory] = useState('All');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Add filter logic to shopItems
  const filteredItems = shopItems.filter((item) => {
    return (
      (filter === 'All Games' || item.storeId.startsWith(filter)) &&
      (category === 'All' || item.rewardsList[0].rewardType === category)
    );
  });

  const [singlePurchaseItemMap, updateSinglePurchaseItemMap] = useState<
    Map<string, boolean>
  >(new Map());

  const populateShopItems = useCallback(async () => {
    try {
      setIsLoading(true);
      if (user) {
        const userMetadata = await getUserMetaData(user?.appUser.user.id ?? '');
        updateLocalStorage(userMetadata);
      }
      /***Fetch shop items */
      const { data } = await getShopItems();
      let shopItemsFromRemote: ShopItemDto[] = data?.shopItemDtoList || [];
      shopItemsFromRemote = shopItemsFromRemote
        .filter((item) => {
          return (
            item.purchaseConstraints.startDate < Date.now() / 1000 &&
            (!item.purchaseConstraints.endDate ||
              item.purchaseConstraints.endDate > Date.now() / 1000)
          );
        })
        .sort((a, b) => {
          return (
            (a.uiMetaData[0].itemRank ?? 1000) -
            (b.uiMetaData[0].itemRank ?? 1000) //Number.MAX_SAFE_INTEGER use if required
          );
        });
      setShopItems(shopItemsFromRemote || []);
      sendViewBiEvent(shopItemsFromRemote);

      updateSinglePurchaseItemsCount(shopItemsFromRemote);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  const updateLocalStorage = (userMetadata: UserShopItemMetadata[]) => {
    const localStorageData: UserShopItemMetadata[] =
      LStorage.get(USER_SHOP_METADATA) || [];
    const newLocalStorageData = userMetadata.map((newItem) => {
      const existingItem = localStorageData.find(
        (item) => item.shopItemId === newItem.shopItemId,
      );
      if (existingItem) {
        return {
          ...newItem,
          metadata: [
            {
              occurrenceId: 0,
              purchaseCount: Math.max(
                newItem.metadata[0].purchaseCount,
                existingItem.metadata[0].purchaseCount,
              ),
            },
          ],
        };
      }
      return newItem;
    });
    localStorageData.forEach((existingItem) => {
      if (
        !newLocalStorageData.some(
          (item) => item.shopItemId === existingItem.shopItemId,
        )
      ) {
        newLocalStorageData.push(existingItem);
      }
    });
    LStorage.set(USER_SHOP_METADATA, newLocalStorageData);
    setShopItemsMetaData(newLocalStorageData || []);
  };

  const updateSinglePurchaseItemsCount = useCallback(
    async (shopItems: ShopItemDto[]) => {
      const localUserShopMetaData: UserShopItemMetadata[] =
        LStorage.get(USER_SHOP_METADATA) || [];
      const singleItemPurchaseCounts = new Map<string, boolean>();
      shopItems.forEach((shopItem) => {
        for (const reward of shopItem.rewardsList) {
          const itemId = getSinglePurchaseItemId(
            reward,
            getAppIdFromStoreId(shopItem.storeId),
            shopItem.uiMetaData[0],
          );
          if (itemId) {
            const userShopItemMetadata = localUserShopMetaData.find(
              (metadata) => metadata.shopItemId === shopItem.id,
            );
            if (
              userShopItemMetadata &&
              userShopItemMetadata.metadata[0].purchaseCount > 0
            ) {
              singleItemPurchaseCounts.set(itemId, true);
            }
          }
        }
      });
      if (user) {
        const userProgression: TUserProgression =
          await getUsersQuestsProgression(user?.appUser?.user.id ?? '');
        const userAppsBattlePassStatusData =
          userProgression?.userAppsBattlePassStatusData;
        if (userAppsBattlePassStatusData) {
          try {
            for (const key in userAppsBattlePassStatusData) {
              if (Number(userAppsBattlePassStatusData[key]) > 0)
                singleItemPurchaseCounts.set(key, true);
            }
          } catch (e) {
            console.error(e);
          }
        }
      }
      updateSinglePurchaseItemMap(singleItemPurchaseCounts);
    },
    [user],
  );

  const updateBattlePassPurchaseCountOnPurchase = useCallback(
    (updatedShopItem: ShopItemDto, newPurchaseCount: number) => {
      const newSinglePurchaseItemMap = new Map(singlePurchaseItemMap);
      for (const reward of updatedShopItem.rewardsList) {
        const itemId = getSinglePurchaseItemId(
          reward,
          getAppIdFromStoreId(updatedShopItem.storeId),
          updatedShopItem.uiMetaData[0],
        );
        if (itemId) {
          newSinglePurchaseItemMap.set(itemId, newPurchaseCount > 0);
        }
      }
      updateSinglePurchaseItemMap(newSinglePurchaseItemMap);
    },
    [singlePurchaseItemMap],
  );

  // Bi Events --------------------------------------------
  const sendViewBiEvent = (shopItems: ShopItemDto[]) => {
    const biData = shopItems.map((shopItem) => {
      const product = {
        productId: shopItem.id,
        ...(shopItem.uiMetaData && shopItem.uiMetaData[0]
          ? shopItem.uiMetaData[0]
          : {}),
      };
      return product;
    });
    commonBiEvents.view({
      event_name: 'iap_flow',
      str_field1: getCurrentTabForBi(),
      str_field6: JSON.stringify(biData),
    });
  };

  useEffect(() => {
    populateShopItems();
  }, [user]);

  return (
    <SuperChampsLayout className="shop-page">
      <div className="container shop-container">
        <div className="filters p-3">
          <p>FILTERS</p>
          <select value={filter} onChange={(e) => setFilter(e.target.value)}>
            <option>All Games</option>
            {shopItems
              .map((shopItem) => getAppIdFromStoreId(shopItem.storeId))
              .filter((value, index, self) => self.indexOf(value) === index)
              .map((storeId) => (
                <option key={storeId} value={storeId}>
                  {utils.getGameNameForFilters(storeId)}
                </option>
              ))}
          </select>
          {/* <select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option>All</option>
            {shopItems
              .map((shopItem) => shopItem.rewardsList[0].rewardType)
              .filter((value, index, self) => self.indexOf(value) === index)
              .map((rewardType) => (
                <option key={rewardType}>{rewardType}</option>
              ))}{' '}
          </select> */}
        </div>
        <div className="shop-list">
          {filteredItems.map((shopItem: ShopItemDto) => (
            <ShopItem
              key={shopItem.id}
              shopItem={shopItem}
              userShopItemMetadata={shopItemsMetaData.find(
                (metadata) => metadata.shopItemId === shopItem.id,
              )}
              singlePurchaseItemMap={singlePurchaseItemMap}
              onPurchaseComplete={updateBattlePassPurchaseCountOnPurchase}
            />
          ))}
          {isLoading && <SCLoader isLoading={true} className="shop-loader" />}
        </div>
      </div>
    </SuperChampsLayout>
  );
};

export default Shop;
