import CONSTANTS from '@/common/constants';
import LStorage from '@/common/storage';
import utils from '@/common/utils';

import { httpClient } from './http-client';
import { getDataVersions } from './quests';

export const newShopItemsDataVersion = async () => {
  try {
    const versions: any = await getDataVersions();
    if (
      parseInt(versions.staticDataVersions.SHOP_ITEM) !==
        parseInt(LStorage.get('SHOP_DATA_VERSION') || '', 10) ||
      !LStorage.get('SHOP_ITEMS')
    ) {
      return versions.staticDataVersions.SHOP_ITEM;
    }
    return false;
  } catch (e) {
    console.error('Error in getQuests\n', e);
  }
};

export const getShopItems = async () => {
  const newVersion = await newShopItemsDataVersion();
  let data;
  if (newVersion) {
    data = await getShopItemsApi(
      parseInt(LStorage.get('SHOP_DATA_VERSION') || '', 0),
      newVersion,
    );
  } else {
    data = LStorage.get('SHOP_ITEMS') || '';
  }
  return data;
};

export const getShopItemsApi = async (
  currentVersion: number,
  newVersion: number,
): Promise<any> => {
  try {
    const prodHost = utils.isProd() ? 'https://static.onjoyride.com' : '';
    const url = `${prodHost}/wallet/apps/${CONSTANTS.JRX_APP_ID}/shopItems?version=${currentVersion}&refVersion=${newVersion}&platform=web&countryCode=US&locale=en&userRole=REGULAR&JRX-APP-ID=${CONSTANTS.JRX_TC_APP_ID}`;
    const { data } = await httpClient.get(url);

    if (data) {
      LStorage.set('SHOP_ITEMS', data);
      LStorage.set('SHOP_DATA_VERSION', data.version);
    }

    return data;
  } catch (e) {
    console.error('Error in getSocialData\n', e);
  }
};

export const validateTokenTransaction = async (
  userId: string,
  payload: any,
) => {
  try {
    const url = `/wallet/user/${userId}/apps/${CONSTANTS.JRX_APP_ID}/validateTokenTransaction`;
    const { data } = await httpClient.post(url, payload);
    return data;
  } catch (e) {
    console.error('Error in validateTokenTransaction\n', e);
  }
};

export const getUserMetaData = async (userId: string) => {
  try {
    const url = `/wallet/apps/${CONSTANTS.JRX_APP_ID}/users/${userId}/shopUserMetadata?platform=Web`;
    const { data } = await httpClient.post(url);
    return data;
  } catch (e) {
    console.error('Error in getUserMetaData\n', e);
  }
};

export const getUsersQuestsProgression = async (userId: string) => {
  try {
    const { data } = await httpClient.get(
      `/gamechallenge/apps/${CONSTANTS.JRX_APP_ID}/users/${userId}/countryIso/US/getUserChallengeData`,
    );
    return data;
  } catch (e) {}
};

export const getInstalledApps = async (userId: string) => {
  try {
    const url = `/user-management/users/${userId}/installedAppIds`;
    const { data } = await httpClient.get(url);
    return data;
  } catch (e) {
    console.error('Error in getUserMetaData\n', e);
  }
};
