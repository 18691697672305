import React, { Dispatch, FC, SetStateAction } from 'react';

import Modal from '@/components/modal-v2';

import { PURCHASE_STATUS } from './shop-item';
import { ShopItemDto } from './types';

export interface IPurchaseStatusModal {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  status: PURCHASE_STATUS | undefined;
  errorMessage: string;
  shopItem: ShopItemDto;
}

const PurchaseStatusModal: FC<IPurchaseStatusModal> = ({
  show,
  setShow,
  status,
  errorMessage,
  shopItem,
}) => {
  const onClose = () => {
    setShow(false);
  };

  return (
    <Modal
      type="black-modal purchase-status-modal"
      onClose={onClose}
      isOpen={show}
      className="medium"
    >
      <div className="purchase-status-container">
        <h3 className="head">
          PURCHASE{' '}
          {status === PURCHASE_STATUS.SUCCESS ? 'SUCCESSFUL' : 'FAILED'}
          <div className={`status-icon ${status}`}></div>
        </h3>
        <img
          className="item-image"
          src={shopItem.uiMetaData[0].imageUrl}
          alt={shopItem.name}
        />

        {status === PURCHASE_STATUS.FAILED && (
          <p>
            {errorMessage?.toLowerCase().includes('not connected')
              ? 'Please log out of your account and log back in, then make your purchase. If the problem persists contact help@superchamps.com'
              : errorMessage?.toLowerCase().includes('does not match')
              ? 'Please try again. If the problem persists contact help@superchamps.com'
              : errorMessage}
          </p>
        )}
        {status === PURCHASE_STATUS.SUCCESS && (
          <>
            <p>The item will be in your game inventory.</p>
            <div className="caption">
              If there are any issues, reach out to us on help@superchamps.com
            </div>
          </>
        )}

        <button className="yellow-button" onClick={onClose}>
          OKAY
        </button>
      </div>
    </Modal>
  );
};

export default PurchaseStatusModal;
