import { getAccount, switchChain } from '@wagmi/core';
import React, { FC, useEffect, useState } from 'react';
import { parseEther } from 'viem';
import {
  useSendTransaction,
  useWaitForTransactionReceipt,
  type BaseError,
} from 'wagmi';

import { getUserMetaData, validateTokenTransaction } from '@/api/shop';
import blIcon from '@/assets/images/bullet-league/logo_small.png';
import ethIcon from '@/assets/images/icons/icon-eth.png';
import iconTimer from '@/assets/images/icons/icon-timer.png';
import rrIcon from '@/assets/images/racket-rampage/logo_small.png';
import { commonBiEvents } from '@/bi/events/common-bi-events';
import CONSTANTS from '@/common/constants';
import {
  getAppIdFromStoreId,
  getSinglePurchaseItemId,
} from '@/common/shopUtils';
import LStorage from '@/common/storage';
import utils, { isBrowser, sha256, isTelegramMiniApp } from '@/common/utils';
import {
  baseMainnetChainId,
  baseSepoliaTestChainId,
} from '@/common/web3-stake-helpers';
import SCLoader from '@/components/common/sc-loader';
import TimerAdvanced from '@/components/timer/timerAdvanced';
import { useAuth } from '@/context/auth-context';
import { useSuperChampsContext } from '@/context/superchamps-context';
import { NETWORKS, useWeb3Context } from '@/context/web3-context';

import PurchaseStatusModal from './purshase-status-modal';
import ShopItemModal from './shop-item-modal';
import { ShopItemDto, UserShopItemMetadata } from './types';

export interface IShopItem {
  shopItem: ShopItemDto;
  userShopItemMetadata: UserShopItemMetadata | undefined;
  singlePurchaseItemMap: Map<string, boolean>;
  onPurchaseComplete: (shopItem: ShopItemDto, newPurchaseCount: number) => void;
}

export enum PURCHASE_STATUS {
  PROCESSIONG = 'processing',
  IN_PROGRESS = 'in_progress',
  SUCCESS = 'success',
  FAILED = 'error',
  EXPIRED = 'expired',
}

const ShopItem: FC<IShopItem> = ({
  shopItem,
  userShopItemMetadata,
  singlePurchaseItemMap,
  onPurchaseComplete,
}) => {
  const { setNotification } = useSuperChampsContext();
  const { user, setShowLogin } = useAuth();
  const { wagmiConfig } = useWeb3Context();
  const {
    data: hash,
    error,
    isPending,
    sendTransaction,
  } = useSendTransaction(wagmiConfig);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isLoading: isConfirming, isSuccess: isConfirmed } =
    useWaitForTransactionReceipt({
      hash,
    });

  const appId = getAppIdFromStoreId(shopItem.storeId);

  const toAddress = utils.isProd()
    ? '0xacCfe00305618bda6228DA5083E7b89914B60185'
    : '0x12227CE1Ee9674405b76747402718ee7Ad94Eeb5';

  const [status, setStatus] = useState<PURCHASE_STATUS>();
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSoldOut, setIsSoldOut] = useState<boolean>(false);

  const [showPurchaseStatus, setShowPurchaseStatus] = useState<boolean>(false);

  const [showShopItemModal, setShowShopItemModal] = useState<boolean>(false);

  // const isRingerChamp = shopItem?.rewardsList[0]?.nftType === 'RINGER_CHAMP';

  const startPurchase = async () => {
    sendClickBI('product_details_view');
    setShowShopItemModal(false);
    if (!user) {
      setShowLogin(true);
      return;
    }
    setStatus(PURCHASE_STATUS.PROCESSIONG);
    setIsLoading(true);
    const data = await checkPurchaseConstraints();
    if (!data) {
      return;
    }
    handlePurchaseFlow();
  };

  const checkPurchaseConstraints = async () => {
    if (shopItem.purchaseConstraints.userPurchaseLimit <= 0) {
      return true;
    }
    const data = await getUserMetaData(user?.appUser.user.id ?? '');
    if (data) {
      // userShopItemMetadata = data;
      const purchaseData: UserShopItemMetadata[] = data;
      const shopItemPurchaseData = purchaseData.find(
        (item) => item.shopItemId === shopItem.id,
      );
      if (shopItemPurchaseData) {
        userShopItemMetadata = shopItemPurchaseData;
        if (
          (shopItemPurchaseData.metadata[0]?.purchaseCount ?? 0) >=
          shopItem.purchaseConstraints.userPurchaseLimit
        ) {
          setErrorMessage('You have reached the purchase limit for this item');
          setStatus(PURCHASE_STATUS.FAILED);
          setIsLoading(false);
          setShowPurchaseStatus(true);
          setIsSoldOut(true);
          onPurchaseComplete(
            shopItem,
            shopItemPurchaseData.metadata[0].purchaseCount,
          );
          return false;
        }
      }
    }
    return true;
  };

  const handlePurchaseFlow = async () => {
    try {
      await switchChain(wagmiConfig, {
        chainId: NETWORKS.CHAMP_TOKEN.envChainId,
        addEthereumChainParameter: NETWORKS.CHAMP_TOKEN,
      });

      const amountToSend = parseEther(String(shopItem.costList[0][0].amount));

      setStatus(PURCHASE_STATUS.IN_PROGRESS);
      // Send transaction
      sendTransaction({
        to: toAddress,
        value: amountToSend,
      });
      console.log('SHOP_ITEM:::Transaction sent:', hash);
    } catch (error: any) {
      // console.log('SHOP_ITEM::: error: ' + error);
      const code = error.code;
      setIsLoading(false);
      setStatus(PURCHASE_STATUS.FAILED);
      setShowPurchaseStatus(true);
      if (code === 'ACTION_REJECTED') {
        setErrorMessage('Transaction has been rejected');
      } else if (code === 'INSUFFICIENT_FUNDS' || code === 'CALL_EXCEPTION') {
        setErrorMessage(
          "Couldn't complete the transaction because of insufficient funds.",
        );
      } else {
        console.error('SHOP_ITEM::: error: ' + error);
        setErrorMessage('Something went wrong');
      }
      sendPurchaseStatusBI('purchase_failure', error.message);
    }
  };

  const updatePurchaseCount = () => {
    if (userShopItemMetadata && userShopItemMetadata.metadata) {
      userShopItemMetadata.metadata[0].purchaseCount += 1;
    } else {
      userShopItemMetadata = {
        shopItemId: shopItem.id,
        metadata: [
          {
            purchaseCount: 1,
            occurrenceId: 0,
          },
        ],
      };
    }
    setIsSoldOut(isSoldOutCheck());
    updateLocalStorage();
    onPurchaseComplete(
      shopItem,
      userShopItemMetadata.metadata[0].purchaseCount,
    );
  };

  const updateLocalStorage = () => {
    const user_metadata = LStorage.get('USER_SHOP_METADATA');
    if (user_metadata) {
      if (!userShopItemMetadata) return;
      const userMetadata: UserShopItemMetadata[] = user_metadata ?? [];
      const shopItemIndex = userMetadata.findIndex(
        (item: UserShopItemMetadata) => item.shopItemId === shopItem.id,
      );
      if (shopItemIndex >= 0) {
        userMetadata[shopItemIndex] = userShopItemMetadata;
      } else {
        userMetadata.push(userShopItemMetadata);
      }
      LStorage.set('USER_SHOP_METADATA', userMetadata);
    } else {
      LStorage.set('USER_SHOP_METADATA', [userShopItemMetadata]);
    }
  };

  const getIcon = () => {
    if (appId === CONSTANTS.JRX_BL_APP_ID) {
      return blIcon;
    } else if (appId === CONSTANTS.JRX_TC_APP_ID) {
      return rrIcon;
    }
    return;
  };

  const isSoldOutCheck = (): boolean => {
    const { purchaseConstraints, rewardsList } = shopItem;
    // Check user purchase limit
    if (
      purchaseConstraints.userPurchaseLimit > 0 &&
      userShopItemMetadata?.metadata
    ) {
      const purchasedCount =
        userShopItemMetadata.metadata[0].purchaseCount ?? 0;
      if (purchaseConstraints.userPurchaseLimit <= purchasedCount) {
        return true;
      }
    }
    // Check single purchase item state
    for (const reward of rewardsList) {
      const itemId = getSinglePurchaseItemId(
        reward,
        getAppIdFromStoreId(shopItem.storeId),
        shopItem.uiMetaData[0],
      );
      if (itemId && singlePurchaseItemMap.get(itemId) === true) {
        return true;
      }
    }
    return false;
  };

  const sendClickBI = (action: string) => {
    const biOptions = {
      event_name: 'iap_flow',
      str_field1: 'buy',
      str_field4: action,
      ...getCommonBiOptions(),
    };
    commonBiEvents.click(biOptions);
  };

  const sendPurchaseStatusBI = (
    purchaseStatus: string,
    failureReason: string | undefined,
  ) => {
    const biOptions = {
      event_name: 'iap_flow',
      str_field1: purchaseStatus,
      str_field3: failureReason || '',
      ...getCommonBiOptions(),
    };
    commonBiEvents.view(biOptions);
  };

  const getCommonBiOptions = () => {
    return {
      str_field2: shopItem.rewardsList[0].rewardType,
      str_field6: shopItem.uiMetaData[0],
      str_field11: shopItem.id,
      str_field12: shopItem.name,
      str_field14: shopItem.costList[0][0].currencyCode,
      str_field17: shopItem.uiMetaData[0].labels[0],
      str_field20: getSbtId(),
      str_field21: appId,
      int_field0: shopItem.rewardsList[0].amount,
      int_field1: shopItem.purchaseConstraints.endDate || 0,
      float_field6: shopItem.costList[0][0].amount,
    };
  };

  const getSbtId = () => {
    if (shopItem.uiMetaData[0].isSbt === 'true') {
      return shopItem.rewardsList[0].id ?? '';
    }
    return shopItem.rewardsList[0].ringerId ?? '';
  };

  const redirectUserToBrowser = async (userConfirmation: boolean) => {
    if (userConfirmation) {
      const redirectUrl =
        'https://app.superchamps.com/shop/?utm_source=telegram&isTelegramRedirect=true&openTab=shop&itemId=' +
        shopItem.id;
      window.Telegram.WebApp.openLink(redirectUrl);
    }
  };

  const triggerShopItemPurchase = async () => {
    if (isBrowser() && isTelegramMiniApp()) {
      if (window.Telegram.WebApp.platform == 'android') {
        await window.navigator.clipboard.writeText(
          'https://app.superchamps.com/shop/?utm_source=telegram&isTelegramRedirect=true&openTab=shop&itemId=' +
            shopItem.id,
        );
        const message =
          'We have copied Super Champs website url to the clipboard. Please open a browser of your choice to complete the purchase.';
        window.Telegram.WebApp.showAlert(message);
      } else {
        const message =
          'You will now be redirected to the Super Champs website to complete purchase.';
        window.Telegram.WebApp.showConfirm(message, redirectUserToBrowser);
      }
    } else {
      setShowShopItemModal(true);
    }
    sendClickBI('shop_view');
  };

  useEffect(() => {
    setIsSoldOut(isSoldOutCheck());
  }, [shopItem, userShopItemMetadata, singlePurchaseItemMap]);

  useEffect(() => {
    if (isLoading) {
      setNotification({
        type: 'black-modal',
        show: true,
        hideClose: true,
        message: (
          <>
            <h4 className="tac mb-4">APPROVAL IN PROGRESS</h4>
            <div className="flex jcc">
              <SCLoader
                isLoading={true}
                className="staking-loader"
                loadingText="Approve the transaction on wallet provider to proceed..."
              />
            </div>
          </>
        ),
        hideCta: true,
      });
    }
    if (!isLoading) {
      setNotification({
        show: false,
        message: '',
      });
      if (status === PURCHASE_STATUS.SUCCESS) {
      } else if (status === PURCHASE_STATUS.FAILED) {
      } else {
      }
    }
    // eslint-disable-next-line
  }, [isLoading, status]);

  useEffect(() => {
    const account = getAccount(wagmiConfig);
    // Validate the transaction
    const tokenTransaction = {
      sourceChain: utils.isProd() ? baseMainnetChainId : baseSepoliaTestChainId,
      transactionId: hash,
      userId: user?.appUser.user.id,
      appId: CONSTANTS.JRX_APP_ID,
      price: shopItem.costList[0][0].amount,
      priceCurrency: shopItem.costList[0][0].currencyCode,
      walletCurrency: shopItem.costList[0][0].currencyCode,
      sourceWalletAddress: account.address,
      destinationWalletAddress: toAddress,
      sourceChainTransactionId: hash,
      category: 'SHOP_PURCHASE',
      purchaseData: {
        shopId: shopItem.id,
        appId: appId,
      },
    };

    if (hash) {
      validateTokenTransaction(
        user?.appUser.user.id ?? '',
        tokenTransaction,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
      ).then((res) => {});
    }
  }, [hash]);

  useEffect(() => {
    if (isPending) {
      setStatus(PURCHASE_STATUS.IN_PROGRESS);
    }
  }, [isPending]);

  useEffect(() => {
    if (isConfirmed) {
      try {
        if (isBrowser()) {
          const emailHash = sha256(user?.appUser?.user?.email || '');
          const userIdHash = sha256(user?.appUser?.user?.id ?? '');
          const eventId = 'ATC_' + Math.floor(Date.now() / 1000);
          const multiplier =
            shopItem.costList[0][0].currencyCode === 'USD' ? 1.0 : 3000.0;
          const amount = shopItem.costList[0][0].amount * multiplier;
          if (window.fbq) {
            window.fbq(
              'track',
              'Purchase',
              {
                currency: 'USD',
                value: amount,
                user_id: user?.appUser.user.id,
                em: emailHash,
              },
              {
                eventID: eventId,
              },
            );
          }
          commonBiEvents.fbConversionPixel({
            str_field2: emailHash,
            str_field3: 'Purchase',
            str_field4: eventId,
            str_field5: userIdHash,
            float_field0: amount,
          });
        }
      } catch (e) {
        console.error('Error firing pixel event', e);
      }
      try {
        if (isBrowser() && window.gtag) {
          const multiplier =
            shopItem.costList[0][0].currencyCode === 'USD' ? 1.0 : 3000.0;
          const value = shopItem.costList[0][0].amount * multiplier;
          const emailHash = sha256(user?.appUser?.user?.email || '');
          window.gtag('event', 'conversion', {
            send_to: 'AW-16648527893/YcaBCO6u_scZEJXA0YI-',
            value: value,
            currency: 'USD',
            enhanced_conversions: {
              email: emailHash,
            },
          });
        }
      } catch (e) {
        console.log('Error firing pixel event', e);
      }
      setIsLoading(false);
      setStatus(PURCHASE_STATUS.SUCCESS);
      setShowPurchaseStatus(true);
      updatePurchaseCount();
      sendPurchaseStatusBI('purchase_success', undefined);

      const isTelegramRedirect = LStorage.get('TELEGRAM_REDIRECT');
      if (isTelegramRedirect && isBrowser()) {
        LStorage.remove('TELEGRAM_REDIRECT');
        window.open('https://t.me/super_champs_hq_bot', '_self');
      }
    }
  }, [isConfirmed]);

  useEffect(() => {
    if (error) {
      setIsLoading(false);
      setStatus(PURCHASE_STATUS.FAILED);
      setShowPurchaseStatus(true);
      setErrorMessage((error as BaseError).shortMessage || error.message);
      sendPurchaseStatusBI('purchase_failure', error.message);
    }
  }, [error]);

  useEffect(() => {
    if (isBrowser()) {
      const query = new URLSearchParams(window.location.search);
      const isTelegramRedirect = query.get('isTelegramRedirect');
      if (isTelegramRedirect) {
        LStorage.set('TELEGRAM_REDIRECT', true);
        const openTab = query.get('openTab');
        if (openTab === 'shop') {
          const itemId = query.get('itemId');
          if (shopItem.id == itemId) {
            setShowShopItemModal(true);
            sendClickBI('shop_view');
          }
        }
      }
    }
  }, []);

  return (
    <>
      {status !== PURCHASE_STATUS.EXPIRED && (
        <div className="shop-item">
          <div className="shop-image-container">
            <img
              className="item-image"
              src={shopItem.uiMetaData[0].imageUrl}
              alt={shopItem.name}
            />
            {!isSoldOut && shopItem.purchaseConstraints.endDate && (
              <div className="shop-timer-holder">
                <div className="skew"></div>
                <div className="timer-container">
                  <img src={iconTimer} alt="iconTimer" />
                  <TimerAdvanced
                    ids={{
                      days: 'dq-days' + shopItem.id,
                      hours: 'dq-hours' + shopItem.id,
                      minutes: 'dq-minutes' + shopItem.id,
                      seconds: 'dq-seconds' + shopItem.id,
                    }}
                    maxDigitCount={3}
                    time={shopItem.purchaseConstraints.endDate}
                    className="claim-timer-center"
                    onTimeUp={() => {
                      setStatus(PURCHASE_STATUS.EXPIRED);
                    }}
                  />
                </div>
              </div>
            )}
            {shopItem.uiMetaData[0].labels.length > 0 && (
              <div className="discount-tag">
                <div className="content">
                  {shopItem.uiMetaData[0].labels[0]}
                </div>
                <div className="skew"></div>
              </div>
            )}
            <div className="app-identifier-tag">
              <img src={getIcon()} alt="app" />
            </div>
            {isSoldOut && <div className="sold-out">One Per Player!</div>}
          </div>

          <div className="details">
            <p className="title ">{shopItem.name}</p>
            <button
              disabled={
                isLoading || status === PURCHASE_STATUS.EXPIRED || isSoldOut
              }
              className="yellow-button flex"
              onClick={async () => {
                triggerShopItemPurchase();
              }}
            >
              <img src={ethIcon} alt="ETH" />
              {shopItem.costList[0][0].currencyCode === 'USD' ? '$' : ''}
              {shopItem.costList[0][0].amount}
              {shopItem.costList[0][0].currencyCode !== 'USD' &&
                `\u2009${shopItem.costList[0][0].currencyCode}`}{' '}
            </button>
          </div>
        </div>
      )}

      <ShopItemModal
        shopItem={shopItem}
        show={showShopItemModal}
        setShow={setShowShopItemModal}
        startPurchase={startPurchase}
      />

      <PurchaseStatusModal
        show={showPurchaseStatus}
        setShow={setShowPurchaseStatus}
        shopItem={shopItem}
        status={status}
        errorMessage={errorMessage}
      />
    </>
  );
};

export default ShopItem;
