import { Reward, UiMetaData } from '@/features/shop/types';

export const getAppIdFromStoreId = (storeId: string) => {
  return storeId.split('.').slice(0, 2).join('.');
};

export const getSinglePurchaseItemId = (
  reward: Reward,
  appId: string,
  uiMetaData: UiMetaData,
) => {
  if (reward.rewardType === 'BattlePass') {
    return appId;
  } else if (reward?.nftType === 'RINGER_CHAMP') {
    return reward.ringerId;
  } else if (
    uiMetaData.isSbt === 'true' &&
    reward.inventoryRewardType === 'Vanity' &&
    reward.vanityItemType === 'ItemType_Character'
  ) {
    return reward.id;
  }
  return null;
};
