import React, { Dispatch, SetStateAction } from 'react';

import ethIcon from '@/assets/images/icons/icon-eth-large.png';
import iconTimer from '@/assets/images/icons/icon-timer.png';
import Modal from '@/components/modal-v2';
import TimerAdvanced from '@/components/timer/timerAdvanced';

import { ShopItemDto } from './types';

export type ShopItemModalRef = {
  shopItem: ShopItemDto;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  startPurchase: () => void;
};

const ShopItemModal = ({
  shopItem,
  show,
  setShow,
  startPurchase,
}: ShopItemModalRef) => {
  const onClose = () => {
    setShow(false);
  };

  const isRingerChamp = shopItem?.rewardsList[0]?.nftType === 'RINGER_CHAMP';
  const isMerchinaryChamp = shopItem.uiMetaData[0].isSbt === 'true';

  return (
    <Modal
      type="black-modal purchase-status-modal"
      onClose={onClose}
      isOpen={show}
      className="shop-item-modal"
    >
      <div className="shop-item-modal-body">
        <img
          className="shop-item-modal-img"
          src={shopItem.uiMetaData[0].imageUrl}
          alt={shopItem.name}
        />
        <div className="shop-item-modal-text-content">
          <div className="shop-item-modal-heading">{shopItem.name}</div>
          {isRingerChamp && (
            <div className="description-tags-shop-item-modal">
              {/* remaining and number tags go here */}
              <div className="shop-item-modal-tag green-tag">SOULBOUND NFT</div>
              <div className="shop-item-modal-tag purple-tag">EPIC</div>
            </div>
          )}
          {isMerchinaryChamp && (
            <div className="description-tags-shop-item-modal">
              {/* remaining and number tags go here */}
              <div className="shop-item-modal-tag green-tag">SOULBOUND NFT</div>
              <div className="shop-item-modal-tag yellow-tag">LEGENDARY</div>
            </div>
          )}
          <div className="shop-item-modal-text-area">
            {shopItem.uiMetaData[0].subTitle.split('|||').map((item, index) => (
              <div key={index}>{item}</div>
            ))}
          </div>
          <hr className="shop-item-line" />
          <div className="shop-item-modal-price-details">
            <div className="shop-item-modal-price">
              {/* price goes here */}
              <img
                className="shop-item-modal-price-image"
                src={ethIcon}
                alt="ETH"
              />
              {shopItem.costList[0][0].currencyCode === 'USD' ? '$' : ''}
              {shopItem.costList[0][0].amount}
              {shopItem.costList[0][0].currencyCode !== 'USD' &&
                `\u2009${shopItem.costList[0][0].currencyCode}`}{' '}
            </div>
            <div className="shop-item-modal-buy-details">
              <div className="shop-timer-holder shop-timer-shop-item-modal">
                <div className="timer-container timer-container-shop-item">
                  {shopItem.purchaseConstraints.endDate && (
                    <>
                      <img src={iconTimer} alt="iconTimer" />
                      <TimerAdvanced
                        ids={{
                          days: 'dq-days-modal' + shopItem.id,
                          hours: 'dq-hours-modal' + shopItem.id,
                          minutes: 'dq-minutes-modal' + shopItem.id,
                          seconds: 'dq-seconds-modal' + shopItem.id,
                        }}
                        maxDigitCount={3}
                        time={shopItem.purchaseConstraints.endDate}
                        className="claim-timer-center"
                      />
                    </>
                  )}
                </div>
              </div>
              <button
                className="yellow-button flex shop-item-buy-btn"
                onClick={startPurchase}
              >
                {' '}
                BUY NOW
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ShopItemModal;
